<template>

<div id="home" class="mb-12">
<!--

  <v-app-bar
    app
    density="compact"
    elevation="0"
  >
    <div class="boxed-container w-full">
      <div class="d-flex align-center mx-lg-6">
        &lt;!&ndash; Left Content &ndash;&gt;
        <v-app-bar-nav-icon
          class="d-block d-lg-none me-2"
          @click="isDrawerOpen = !isDrawerOpen"
        >
          <v-img
            width="30"
            contain
            alt=""
            :src="require('@/assets/images/avatars/logo.png')"
          ></v-img>
        </v-app-bar-nav-icon>

        <v-app-bar-title>Reader</v-app-bar-title>
        <v-spacer></v-spacer>

        &lt;!&ndash; Right Content
        <a
          href="https://3ag-edition.com/"
          target="_blank"
          rel="nofollow"
          class="mr-3"
        >
          <v-img
            width="30"
            contain
            alt=""
            :src="require('@/assets/images/avatars/logo.png')"
          ></v-img>
        </a> &ndash;&gt;
        <theme-switcher></theme-switcher>
        <app-bar-user-menu
          :user="user"
          :user_id="user.id"
        ></app-bar-user-menu>
      </div>
    </div>
  </v-app-bar>
-->

  <banner></banner>

<!--      <contest-chapters  v-if="contest.advertising.id" :contest="contest.contest" ></contest-chapters>-->

  <v-card   v-if="contest.advertising.id" elevation="0" class="mb-6">
    <v-card-title>
      <v-icon color="success" class="mr-3">{{icons.mdiCreation}}</v-icon>
      Concours One-shot / {{contest.contest.cmstext.title}}
      <v-spacer></v-spacer>


      <v-btn icon
             :to="'contest/'+contest.contest.id"
             small
             color="primary"
      >
        <v-icon>{{icons.mdiArrowRight}}</v-icon>
      </v-btn>
    </v-card-title>
  <contest-participant-component
    :idcontest="contest.contest.id"
    :user="user"
    :user_id="user.id"
    :postoption="{'cover:isNull':0}"
                                  :per_page="8"
  ></contest-participant-component>
  </v-card>
<!--  <sponsor-prompt></sponsor-prompt>
     <home-new-catalog></home-new-catalog>-->

  <home-new-chapters
    :title="'Dernieres Sorties'"
    :options="'chapter'">
  </home-new-chapters>

  <home-new-chapters
    :title="'Light Novel'"
    :options="'lightnovel'"
  ></home-new-chapters>

  <v-card
    v-if="contest.advertising.id"
    max-width="800"
    class="mt-6 mb-6 ma-auto"
  >
    <v-img
      :src="contest.advertising.image"
      class="white&#45;&#45;text align-end"
      gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.6)"
      height="200px"
    >
      <v-card-title  style="color: whitesmoke !important;" class="">
        <v-icon color="white" class="mr-3">{{icons.mdiAccountGroupOutline}}</v-icon>
        {{ contest.contest.nb_participant }} <span class="ml-3 hidden-s-only">Participants</span>
        <v-spacer></v-spacer>
        <v-btn
          :to="'contest/'+contest.contest.id"
          color="primary"
        >
          En savoir plus
        </v-btn>
      </v-card-title>
    </v-img>
  </v-card>

  <home-new-posts
    :user="user"
    :user_id="user.id"
    @login="()=>dialog.authentication = true"
  ></home-new-posts>

  <home-new-chapters
    :title="'One Shot'"
    :options="'oneshot'"
  ></home-new-chapters>

  <v-card elevation="0" class="mb-6">
    <v-card-title>
      <v-icon class="mr-3">{{icons.mdiCommentAlertOutline}}</v-icon>
      Derniers Commentaires
      <v-spacer></v-spacer>

    </v-card-title>
    <v-card elevation="0" v-if="lastcomments.length == 0"  v-for="n in 3"
            :color="`grey darken-2 `"
            :key="n"
    >
      <v-skeleton-loader
        class="mx-auto"
        :height="100"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </v-card>

    <v-list dense>
      <template v-for="(item, index) in lastcomments">
        <v-divider
          :key="index"
        ></v-divider>
        <comment-item-component
          :model="'chapter'" :item="item" :user="user"></comment-item-component>
      </template>
    </v-list>

  </v-card>

  <v-card elevation="0">
    <v-card-title>
      <v-icon class="mr-3">{{icons.mdiStarOutline}}</v-icon>
      Classement
      <v-spacer></v-spacer>

      <v-btn icon
             to="/top-ranking"
             small
             color="primary"
      >
        <v-icon>{{icons.mdiArrowRight}}</v-icon>
      </v-btn>

    </v-card-title>
    <v-card elevation="0" v-if="lastcomments.length == 0"  v-for="n in 3"
            :color="`grey darken-2 `"
            :key="n"
    >
      <v-skeleton-loader
        class="mx-auto"
        :height="100"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </v-card>
    <v-card-text style="position: relative" class="position-relative">
      <v-row  >
        <v-col cols="12" lg="6" md="6">
          <v-list >
            <template v-for="(item, index) in comicbook_topviews">
              <!--            <v-divider
                            :key="index"
                          ></v-divider>-->
              <top-view-item-component
                v-if="index <3"
                :key="'item'+item.id"
                :item="item"
                :index="index"
              ></top-view-item-component>
            </template>
          </v-list></v-col>
        <v-col cols="12" lg="6" md="6">
          <v-list >
            <template v-for="(item, index) in comicbook_topviews">
              <!--            <v-divider
                            :key="index"
                          ></v-divider>-->
              <top-view-item-component
                v-if="index >= 3"
                :key="'item'+item.id"
                :item="item"
                :index="index"
              ></top-view-item-component>

            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <home-new-gallery></home-new-gallery>
</div>


</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiCartOutline,
  mdiClose,
  mdiGithub,
  mdiArrowRight,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountGroupOutline,
  mdiStarOutline,
  mdiImageAlbum,
  mdiCommentAlertOutline, mdiCreation, mdiDrawing, mdiDrawingBox
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Banner from '@/components/Banner'
import HomeNewChapters from '@/components/HomeNewChapters.vue'
import HomeNewCatalog from '@/components/HomeNewCatalog.vue'
import HomeNewGallery from '@/components/HomeNewGallery.vue'
import ContestWinner from '@/components/ContestWinner.vue'
import ContestChapters from '@/components/ContestChapters.vue'
import PostComponent from '@/components/PostComponent.vue'
import HomeNewPosts from "@/components/HomeNewPosts.vue";
import SponsorPrompt from "@/layouts/components/SponsorPrompt.vue";
import TopViewItemComponent from "@/components/TopViewItemComponent.vue";
import store from "@/store";
import UserAvatar from "@/components/UserAvatar.vue";
import AppBarUserMenu from "@/layouts/components/AppBarUserMenu.vue";
import BottomNavigation from "@/layouts/components/BottomNavigation.vue";
import ThemeSwitcher from "@/layouts/components/ThemeSwitcher.vue";
import CommentItemComponent from "@/components/CommentItemComponent.vue";
import ContestParticipantComponent from "@/components/contest/ContestParticipantComponent.vue";

export default {
  components: {
    ContestParticipantComponent,
    CommentItemComponent,
    ThemeSwitcher,
    BottomNavigation,
    AppBarUserMenu,
    UserAvatar,
    TopViewItemComponent,
    SponsorPrompt,
    HomeNewPosts, PostComponent, HomeNewGallery, HomeNewCatalog, HomeNewChapters, Banner, ContestWinner, ContestChapters },
  data(){
      return {
        isDrawerOpen : false
      }
  },
  setup() {

    store.commit('updateMetatag', {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'BD, Manga, Webtoons, Light novels et artbooks gratuit en ligne - 3agReader',
      content: "Publiez et lisez gratuitement vos mangas, bandes dessinées, webtoons et light novels en ligne. Découvrez des talents, lisez-les, soutenez-les.",
      // all titles will be injected into this template
      titleTemplate: '%s | Lecture en ligne et de Creation de contenu, Bande dessine, Manga, Comics et bien d autre'
    });

    const $vuetify = getVuetify()
    const user = Drequest.getUser()
    const isPasswordVisible = ref(false)

    const tab = ref('one')
    const items = ref([])
    const challenges = ref([])
    const lastcontest = ref([])
    const lastimages = ref([])
    const lastcomments = ref([])
    const comicbook_topviews = ref([])
    const product = ref({ shop: {} })
    const loader = ref({
      news: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const mcs = ref([])
    const advertising = ref({})
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 120
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })
    const slider_height = computed(() => {
      if ($vuetify.breakpoint.xl) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.lg) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.md) {
        return {
          news: 210,
          product: 210,
        }
      }
      if ($vuetify.breakpoint.sm) {
        return {
          news: 180,
          product: 180,
        }
      }
      if ($vuetify.breakpoint.xs) {
        return {
          news: 150,
          product: 150,
        }
      }

      return {
        news: 270,
        product: 270,
      }
    })
    const showarrow = computed(() => {
      if ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) {
        return false
      }

      return true
    })

    const postll = ref({})
    // const user = Drequest.getUser()
    const contest = ref({advertising:{}, lastcontest:{}, challenges:[], contest:{}})
    const init = (next) => {
      loader.value.reloadchapter = false
      loader.value.reloadprod = false

      Drequest.api('contest.homepage')
        .get(response => {
          console.log(response)
           contest.value = response
          /*
          localStorage.set('advertising', JSON.stringify(contest.value.advertising))
          localStorage.set('contest', JSON.stringify(contest.value.contest))
          */
          challenges.value = response.challenges
          lastcontest.value = response.lastcontest
          lastimages.value = response.lastimages
          lastcomments.value = response.lastcomments
          comicbook_topviews.value = response.comicbook_topviews
        })

    }
    init(1)

    return {
      init,
      user,
      comicbook_topviews,
      lastcomments,
      isPasswordVisible,
      contest,
      loader,
      items,
      columns,
      mcs,
      showarrow,
      slider_height,
      tab,

      icons: {
        mdiCreation,
        mdiArrowRight,
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAccountGroupOutline,
        mdiCommentAlertOutline,
        mdiStarOutline,
        mdiImageAlbum,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
#home .v-tabs-bar {
  border-radius: inherit;
  height: inherit !important;
}
.v-application.theme--light #home .v-tabs:not(.v-tabs--vertical) {
  box-shadow: none !important;
}
@media (max-width: 450px) {
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none !important;
    visibility: hidden;
  }
}
</style>
