<template>
  <div>
    <v-card
      elevation="0"
      class=""
    >
      <v-card-title class="pa-2">
        <v-icon class="mr-3">{{icons.mdiHistory}}</v-icon>
        {{title}}
        <v-spacer></v-spacer>

        <v-btn icon
               to="/chapters"
               small
               color="primary"
        >
          <v-icon>{{icons.mdiArrowRight}}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-actions
        v-if="loader.reloadchapter"
        class="text-center"
      >
        <v-btn
          class="ma-auto"
          color="primary"
          @click="init()"
        >
          Recharger
        </v-btn>
      </v-card-actions>
      <template v-else>
        <v-sheet
          v-if="loader.news"
          class="mx-auto"
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="n in 9"
              :key="'lnew'+n"
              v-slot="{ }"
            >
              <v-card
                :color="`grey darken-2 `"
                class="pa-3"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  :height="slider_height.news"
                  :width="columns"
                  type="card"
                ></v-skeleton-loader>
              </v-card>
            </v-slide-item>

            <!--          <v-slide-item v-else
                                    v-for="(item, index) in newchapters"
                                    :key="'new-'+index"
                                    v-slot="{ }"
                      >
                      </v-slide-item>-->
          </v-slide-group>
        </v-sheet>
        <v-sheet
          v-else
          class="mx-auto "
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="(item, index) in newchapters"
              :key="'new-'+index"
              v-slot="{ }"
            >

              <v-card v-if="item.comicbook_seo"
                elevation="0"
                :to="'/scan/'+item.comicbook_seo+'/'+item.id"
                min-width="108"
                :width="columns"
                class="ma-1"
              >
                <v-img
                  class="white--text align-end"
                  :src="item.cover_src" aspect-ratio="9/16"
                  :lazy-src="require('@/assets/images/holder.jpg')"
                >
<!--                  <v-chip style="position:absolute; top: 10px; right: 10px" color="warning">{{item.number}}</v-chip>-->
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 "
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <template v-slot:error>
                    <v-img
                      class="mx-auto"
                      height="300"
                      max-width="500"
                      :src="require('@/assets/images/holder.jpg')"
                    ></v-img>
                  </template>
                  <v-card-title
                    gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)" style="color: whitesmoke !important;" class=" pa-1  bg-gradient-darker">
<!--                    <small class="text-subtitle-2"><v-icon small color="white">{{icons.mdiEyeOutline}}</v-icon> {{ item.nbviews }}</small>-->
                    <v-spacer></v-spacer>
                    <span class=" text-h5 font-secondary">#{{ item.number }}</span>
                  </v-card-title>
                </v-img>
                <v-card-subtitle style="line-height: 18px" class="title-inline pa-1 pb-0 ">
                  <h4>{{ item.comicbook_name }}</h4>
                  <span class="title-inline flex justify-center  text--lighten-1">
                  <v-icon size="15" >{{icons.mdiEyeOutline}}</v-icon> {{ item.nbviews }}
                </span>
                </v-card-subtitle>
              </v-card>
              <v-card v-else
                outlined :to="`/one-shot/${item.slug}/`+item.id"
                min-width="108"
                :width="columns"
                class="ma-1"
              >
                <v-img
                  class="white--text align-end"
                  :src="item.cover_src"
                  :lazy-src="require('@/assets/images/holder.jpg')"
                  gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 "
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-card-title style="color: whitesmoke !important;" class="title-inline white--text pa-1 text-white">
                    <small class="text-subtitle-2"><v-icon small color="white">{{icons.mdiEyeOutline}}</v-icon> {{ item.nbviews }}</small>
                    <v-spacer></v-spacer>
<!--                    <span class="font-secondary text-h5">#{{ item.number }}</span>-->
                  </v-card-title>
                </v-img>
              </v-card>

            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </template>
    </v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiHistory,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline, mdiCartOutline, mdiClose, mdiArrowRight
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'

export default {
  props:{
      options:{
        type: String,
        default: "",
      },
      title:String
  },
  setup(props) {
    const $vuetify = getVuetify()
    const isPasswordVisible = ref(false)
    const productdialog = ref(false)
    const email = ref('')
    const password = ref('')
    const items = ref([])
    const newchapters = ref([])
    const oneshotchapters = ref([])
    const product = ref({ shop: {} })
    const loader = ref({
      news: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const mcs = ref([])
    const advertising = ref({})
    const socialLink = [
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 210
      }
      if ($vuetify.breakpoint.xs) {
        return 150
      }

      return 270
    })

    const showarrow = computed(() => {
      if ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) {
        return false
      }

      return true
    })

    // const user = Drequest.getUser()

    const init = () => {
      loader.value.reloadchapter = false
      loader.value.reloadprod = false

      let url;
      if (props.options === 'chapter')
        url = 'chapter.homepage?dfilters=on&next=1&per_page=9&jsonmodel=home&category.slug:neq=lightnovel';
      else if (['lightnovel', 'webtoon',].includes(props.options))
        url = 'chapter.homepage?dfilters=on&next=1&per_page=9&jsonmodel=home&category.slug:eq='+props.options;
      else
        url = 'lazyloading.chapter?dfilters=on&next=1&per_page=9&status:eq=1&comicbook_id:isNull=1&dsort=id desc';

      Drequest.api(url)// &user_id=269&subscription_id=1
        .get(response => {
          console.log(response)
          loader.value.news = false
          newchapters.value = response.listEntity
        })
        .fail(e => {
          loader.value.news = false
          loader.value.reloadchapter = true
          console.log(e)
        })

    }
    init()
    const slider_height = computed(() => {
      if ($vuetify.breakpoint.xl) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.lg) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.md) {
        return {
          news: 210,
          product: 210,
        }
      }
      if ($vuetify.breakpoint.sm) {
        return {
          news: 180,
          product: 180,
        }
      }
      if ($vuetify.breakpoint.xs) {
        return {
          news: 150,
          product: 150,
        }
      }

      return {
        news: 270,
        product: 270,
      }
    })

    return {
      init,

      isPasswordVisible,
      productdialog,
      advertising,
      product,
      loader,
      email,
      password,
      socialLink,
      items,
      newchapters,
      oneshotchapters,
      columns,
      mcs,
      showarrow,
      slider_height,

      icons: {
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowRight,
        mdiHistory,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
